import { Component, Input, OnDestroy, ViewChild, forwardRef } from "@angular/core";
import { AbstractControl, ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, ValidationErrors } from "@angular/forms";
import {
    FeatureFlags,
    IDepartmentDTO,
    IOrgChartEntry,
    IOrgChartVisibility,
    IOrgChartVisibilityEntry,
    InheritanceType,
    VisibilityName,
    VisibilityTargetLeader,
    VisibilityType,
} from "@vierkant-software/types__api";
import { GCFormGroup, GCFormDictionary, FC_G } from "src/app/modules/ngx-draft";
import { Globals } from "src/app/services/globals.service";
import { AppService } from "src/services/app.service";
import { toDict } from "src/util/array";
import { Dict, Option } from "src/util/types/global";
import { OrgChartEntry } from "../gc-org-chart/gc-org-chart.component";
import { Subscription } from "rxjs";
import { VisibilityInheritanceComponent } from "../visibility-inheritance/visibility-inheritance.component";

type InheritanceDetail = {origin: OrgChartEntry, visibility: IOrgChartVisibilityEntry, hasInheritanceWarning: boolean};
type Value = {
    entry: IOrgChartEntry;
    entries: IOrgChartEntry[];
};

@Component({
    selector:    "v-visibility",
    styleUrls:   ["./visibility.component.sass"],
    templateUrl: "./visibility.component.haml",
        providers:   [{
        provide:     NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => VisibilityComponent),
        multi:       true
    }],
})
/**
 * TODO: properly reset `this.entryDict[depID]` inheritance once modal closes.
 */
export class VisibilityComponent implements ControlValueAccessor, OnDestroy {
    private readonly subscriptions: Subscription[] = [];

    private VisibilityFeatureFlag: Partial<Record<VisibilityName, FeatureFlags>> = {
        shift_planning: FeatureFlags.shift_planning,
        calendar:       FeatureFlags.calendar,
    };
    private _allDepartments: IDepartmentDTO[];
    private _value: Value;
    private entryDict: Dict<OrgChartEntry> = {};
    public inheritanceDict: Dict<InheritanceType> = {};

    public readonly CUSTOM_TYPE: VisibilityType = VisibilityType.custom;
    @Input() public rootID?: string;

    @ViewChild("inheritance", {static: false}) public inheritanceDialog: VisibilityInheritanceComponent;
    public departmentOptions: Option<string>[] = [];
    public departments: Record<string, IDepartmentDTO> = {};
    public form = new GCFormGroup<IOrgChartVisibility>({
            useDefault:   [true],
            defaultEntry: new GCFormGroup<IOrgChartVisibilityEntry>({
                ID:                  [undefined],
                type:                [VisibilityType.all],
                departmentIDs:       [undefined],
                leaderTarget:        [VisibilityTargetLeader.leader],
                leaderType:          [VisibilityType.all],
                leaderDepartmentIDs: [undefined],
                inheritance:         ['inherit'],
            }),
            entries: new GCFormDictionary<IOrgChartVisibilityEntry>({
                ID:                  [undefined],
                type:                [VisibilityType.all],
                departmentIDs:       [undefined],
                leaderTarget:        [VisibilityTargetLeader.leader],
                leaderType:          [VisibilityType.all],
                leaderDepartmentIDs: [undefined],
                inheritance:         ['inherit'],
            }, "ID")
        }, (controls) => {
            const error = {} as ValidationErrors;
            const {value} = controls;
            const entries = Object.keys(controls.controls.entries.controls).map((x: VisibilityName) => [x, controls.controls.entries.controls[x]]);
            const defaultError = this.validateEntry(controls.controls.defaultEntry as unknown as FC_G<IOrgChartVisibilityEntry>, !value.useDefault);
            // eslint-disable-next-line max-len
            const entryErrors = (entries.map(([key, val]) => [key, this.validateEntry(val as unknown as FC_G<IOrgChartVisibilityEntry>, value.useDefault)] as [string, ValidationErrors]));
            Object.keys(defaultError).map(key => error[`defaultEntry.${key}`] = defaultError[key]);
            entryErrors.forEach(([key, err]: [string, ValidationErrors]) => {
                Object.keys(err).map((prop: string) => error[`entries.${key}.${prop}`] = err[prop]);
            });
            return error;
        }).getFormGroup(this.fb, {}) as FC_G<IOrgChartVisibility>;
    private inheritanceDetail: Dict<InheritanceDetail> = {};
    private validateEntry(control: FC_G<IOrgChartVisibilityEntry>, ignore: boolean): ValidationErrors {
        const {value} = control;
        return {
            'type':
                !ignore && !value.type ? "Die Sichtbarkeit muss gesetzt sein." : false,
            'departmentIDs':
                !ignore && value.type === "custom" && !value.departmentIDs?.length ? "Es muss mindestens eine Abteilung ausgewählt sein." : false,
            'leaderType':
                !ignore && value.leaderTarget && !value.leaderType ? "Einstellungen für die Abteilungsleitung müssen gesetzt sein." : false,
            'leaderDepartmentIDs':
                !ignore && value.leaderType === "custom" && !value.leaderDepartmentIDs?.length ? "Es muss mindestens eine Abteilung ausgewählt sein." : false,
        };
    }

    public getDetail(visibilityName?: VisibilityName) {
        return this.inheritanceDetail[visibilityName ?? "default"];
    }
    public setDetail(visibilityName: VisibilityName | undefined, value: InheritanceDetail) {
        return this.inheritanceDetail[visibilityName ?? "default"] = value;
    }
    public isRoot: boolean;
    public onModelChange: Function = (_value: Value) => {};
    public onModelTouched: Function = () => {};
    public visibilityGroups = Object.keys(VisibilityName).filter(x =>
        this.globals.VisibilityNameLabel[x as VisibilityName] && this.isFeatureActive(x)
    ) as VisibilityName[];
    /**
     * No value means "default", otherwise one of the VisibilityName
     */
    public visibilityToEdit?: VisibilityName;
    public visibiltyLeaderTargetOptions: Option<string>[] = Object.values(VisibilityTargetLeader).map(x => ({
        label: this.globals.VisibilityTargetLeader[x],
        value: x
    }));
    public visibiltyTypeOptions: Option<string>[] = Object.values(VisibilityType).map(x => ({
        label: this.globals.VisibilityType[x],
        value: x
    }));

    constructor(public readonly globals: Globals, private readonly app: AppService, private readonly fb: FormBuilder) {}

    @Input({ alias: "departments" })
    public set allDepartments(value: IDepartmentDTO[]) {
        this._allDepartments = value;
        this.departments = toDict(value, x => x.departmentID);
        this.departmentOptions = value.map(x => ({
            label: x.name,
            value: x.departmentID
        }));
    }

    public get allDepartments(): IDepartmentDTO[] {
        return this._allDepartments;
    }

    public getDefaultVisibility(visibilityName?: VisibilityName): IOrgChartVisibilityEntry {
        return Object.create({ID: visibilityName, type: VisibilityType.all, leaderType: VisibilityType.all, inheritance: "inherit"});
    }

    public get defaultInheritance() {
        return this.isRoot ? "custom" : "inherit";
    }
    public get entries() {
        return this.value?.entries;
    }

    public get value(): Value {
        return this._value;
    }


    public set value(value: Value) {
        this._value = value;
        this.form.reset({},{emitEvent: false});
        if (!value) return;
        this.entryDict = toDict(this.value.entries, x => x.ID);

        this.inheritanceDetail = {};
        this.isRoot = !this.departments[this.rootID].parentID;
        this.setupVisibilityControls(value.entry.visibility);

        let inheritance = this.updateInheritance(null, value.entry.visibility.defaultEntry.inheritance);
        this.form.patchValue({useDefault: value.entry.visibility.useDefault, defaultEntry: inheritance.visibility}, {emitEvent: false});
        this.setupControlStatus(this.form.get("defaultEntry") as FC_G<IOrgChartVisibilityEntry>);

        this.visibilityGroups.map(x => {
            let inheritance = this.updateInheritance(x, value.entry.visibility.entries[x]?.inheritance);
            const control = this.form.get("entries").get(x) as FC_G<IOrgChartVisibilityEntry>;
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const {ID:_UNUSED_ID, ...details} = inheritance?.visibility;
            control.patchValue(details, {emitEvent: false});
            this.setupControlStatus(control);
        });
    }

    public getInheritance(departmentID: string, visibilityName?: VisibilityName, assignedInheritance?: InheritanceType): InheritanceDetail {
        let current = this.entryDict[departmentID];
        let entry = this.getEntry(current, visibilityName);
        const initialInheritance = entry.inheritance;

        if (assignedInheritance)
            entry.inheritance = assignedInheritance;

        while (current.parentID && entry.inheritance === "inherit"){
            current = this.getParent(current.ID);
            entry = this.getEntry(current, visibilityName);
            entry.ID ??= visibilityName;
        }

        entry.inheritance = initialInheritance;

        if (departmentID === this.rootID && !this.departments[this.rootID].parentID) entry.inheritance = this.defaultInheritance;
        else if (assignedInheritance) entry.inheritance = assignedInheritance;
        return {origin: current, visibility: entry, hasInheritanceWarning: current.ID !== departmentID && current.visibility.useDefault};
    }

    private getEntry(entry: OrgChartEntry, visibility?: VisibilityName) {
        let result;
        if (visibility === null || entry.visibility.useDefault)
            result = entry.visibility.defaultEntry;
        else
            result = entry.visibility.entries[visibility];

        if (!result) result = this.getDefaultVisibility(visibility);
        result = {...result};
        if (!entry.parentID) result.inheritance = this.defaultInheritance;
        result.inheritance ??= "inherit";
        return result;
    }

    public getVisibilityName(value: unknown) {
        if (!value) return "Alle Themenbereiche";
        return this.globals.VisibilityNameLabel[value as VisibilityName] ?? "unknown";
    }

    public getVisibilityTargetTrueValue(form: AbstractControl, target_prop: string, defaultValue = "leader") {
        const value = form.value[target_prop];
        return value ?? defaultValue;
    }

    public ngOnDestroy() {
        this.cleanupSubscriptions();
    }

    public onInheritanceClose(inheritanceData: Record<string, InheritanceType>) {
        try {
            if (!inheritanceData) return;
            this.inheritanceDict = {};
            const visibility = this.visibilityToEdit;
            Object.entries(inheritanceData).forEach(([depID, inheritance]) => {
                const department =  this.entryDict[depID];
                if (!department) return;

                // Parent settings might differ as they may not be the same for each visibility. 
                if (visibility) {
                    // if child had default enabled, split it up
                    if (department.visibility.useDefault) {
                        this.visibilityGroups.forEach(name => {
                            this.inheritanceDict[depID  + "&" + name] =
                            // eslint-disable-next-line max-len
                            (department.visibility.useDefault ? department.visibility.defaultEntry?.inheritance : department.visibility.entries[name]?.inheritance) ?? "inherit";
                            // this.entryDict[depID].visibility.entries[name] =
                            //     department.visibility.entries[name] = (department.visibility.defaultEntry ??= this.getDefaultVisibility(name));
                        });
                        // department.visibility.useDefault = false;
                    }
                    // adjust only specific visibility in department
                    this.inheritanceDict[depID + "&" + visibility] = inheritance;


                    // this.entryDict[depID].visibility.entries[visibility].inheritance =
                    //     (department.visibility.entries[visibility] ??= this.getDefaultVisibility(visibility)).inheritance = inheritance;
                } else {
                    // overwrite child entries with default entry
                    this.inheritanceDict[depID] = inheritance;
                    // this.entryDict[depID].visibility.defaultEntry.inheritance =
                        // (department.visibility.defaultEntry ??= this.getDefaultVisibility()).inheritance = inheritance;
                }
            });
        } finally {
            this.visibilityToEdit = undefined;
        }
    }

    public registerOnChange(fn: Function): void {
        this.onModelChange = fn;
    }

    public registerOnTouched(fn: Function): void {
        this.onModelTouched = fn;
    }

    public writeValue(obj: Value) {
        this.value = obj;
    }

    private cleanupSubscriptions() {
        let subscription;
        while (subscription = this.subscriptions.shift())
            subscription.unsubscribe();
    }

    private getParent(nodeID: string) {
        return this.entryDict[this.entryDict[nodeID].parentID];
    }

    private isFeatureActive(name: string | VisibilityName) {
        return !this.VisibilityFeatureFlag[name as VisibilityName] || this.app.getFeature(this.VisibilityFeatureFlag[name as VisibilityName]);
    }

    private isVisibilityInvalid(value: IOrgChartVisibilityEntry): string | false  {
        return false;
        // TODO: add validity check
        if (!value) return "Keine gültige Sichtbarkeit.";
        return isEntryValid(value);

        function isEntryValid(entry: IOrgChartVisibilityEntry): string | false  {
            if (!entry.type)
                return "Alle Werte müssen gesetzt sein.";
            if (entry.leaderTarget && !entry.leaderType)
                return "Alle Werte müssen gesetzt sein.";
            return false;
        }
    }

    private setupControlStatus(control: FC_G<IOrgChartVisibilityEntry>, value: InheritanceType = control.get("inheritance").value) {
        if (value === "inherit"){
            control.patchValue(this.getDetail(control.value.ID).visibility, {emitEvent: false}); // id becomes unset for some reason
            control.disable({ emitEvent: false });
        } else {
            control.enable({ emitEvent: false });
        }

        control.get("inheritance").enable({ emitEvent: false });
    }

    private setupVisibilityControls(value: IOrgChartVisibility) {
        this.form.patchValue({
            useDefault:   value.useDefault,
            defaultEntry: value.defaultEntry,
        }, {emitEvent: false});
        this.subscriptions.push(
            this.form.get("defaultEntry").get("inheritance").valueChanges.subscribe((value: InheritanceType) => {
                this.updateInheritance(undefined, value);
                this.setupControlStatus(this.form.get("defaultEntry") as FC_G<IOrgChartVisibilityEntry>, value);
            })
        );
        const entries = this.form.get(["entries"]) as FormGroup;
        this.visibilityGroups.forEach(x => {
            const val = value.entries[x];
            entries.addEntry(x, {
                ID:                  x,
                type:                val?.type ?? VisibilityType.all,
                departmentIDs:       val?.departmentIDs,
                leaderType:          val?.leaderType,
                leaderTarget:        val?.leaderTarget,
                leaderDepartmentIDs: val?.leaderDepartmentIDs,
                inheritance:         val?.inheritance ?? "inherit",
            } as IOrgChartVisibilityEntry);
            const control = entries.get(x) as FC_G<IOrgChartVisibilityEntry>;
            this.subscriptions.push(
                control.get("inheritance").valueChanges.subscribe((value: InheritanceType) => {
                    this.updateInheritance(x, value);
                    this.setupControlStatus(control, value);
                })
            );
        });
    }

    private updateInheritance(visibilityName?: VisibilityName, inheritance?: InheritanceType) {
        if (!this.entryDict[this.rootID]) return;
        return this.setDetail(visibilityName, this.getInheritance(this.rootID, visibilityName, inheritance));
    }
}
