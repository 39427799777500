/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from "@angular/core";
import { DEFAULT_LANGUAGE, FakeMissingTranslationHandler, MissingTranslationHandler,
    TranslateCompiler, TranslateDefaultParser, TranslateFakeCompiler, TranslateFakeLoader,
    TranslateLoader, TranslateModule, TranslateModuleConfig, TranslateParser, TranslateService,
    USE_DEFAULT_LANG, USE_EXTEND, USE_STORE }
from "@ngx-translate/core";
import { DateTime } from "luxon";
import { Globals } from "src/app/services/globals.service";


TranslateModule.forChild = function (config: TranslateModuleConfig = {}) {
    return {
        ngModule:  TranslateModule,
        providers: [
          config.loader || {provide: TranslateLoader, useClass: TranslateFakeLoader},
          config.compiler || {provide: TranslateCompiler, useClass: TranslateFakeCompiler},
          config.parser || {provide: TranslateParser, useClass: GcTranslateParser},
          config.missingTranslationHandler || {provide: MissingTranslationHandler, useClass: FakeMissingTranslationHandler},
          {provide: USE_STORE, useValue: config.isolate},
          {provide: USE_DEFAULT_LANG, useValue: config.useDefaultLang},
          {provide: USE_EXTEND, useValue: config.extend},
          {provide: DEFAULT_LANGUAGE, useValue: config.defaultLanguage},
          TranslateService
        ]
    };
};



function DATE_parser(value: DateTime, subformat: string | undefined) {
    if (!DateTime.isDateTime(value))
        return "UNKNOWN_DATE";
    if (!subformat)
        subformat = 'DATETIME_SHORT';
    if (subformat in Globals.DateTime)
        return value.toLocaleString((Globals.DateTime as any)[subformat as any]);
    return value.toFormat(subformat);
}


@Injectable()
export class GcTranslateParser extends TranslateDefaultParser {
    static formatParsers: Record<string, (value: any, subformat: string | undefined) => string> = {
        'DATE': DATE_parser,

    };

    getValue(target: any, key: string) {
        const [skey, format, subformat] = typeof key === 'string' ? key.split(':') : [key, undefined, undefined];
        let res = super.getValue(target, skey);
        if (!format)
            return res;
        if (format in GcTranslateParser.formatParsers)
            return GcTranslateParser.formatParsers[format](res, subformat);
        throw new Error('Unknown format: ' + format + ' while parsing translation token ' + key);
    }
}