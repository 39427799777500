import { NgModule, inject } from '@angular/core';
import { ActivatedRouteSnapshot, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { FeatureFlags, FrontendPermissions } from '@vierkant-software/types__api';
import { AuthGuard } from 'src/services/auth.guard';
import { additional_routes } from './app-routing.module.dev';
import { InternalAppService } from 'src/services/app.service';

const modalRoutes = ['calendar', 'absence/modal', 'credentials', 'contract-employee',
'demo', 'users', 'person', 'exit', 'time-correction-profile', 'absence-credit-account','user-time-account','projects',
'user-document', "person-shift",'eAU-request','datev-connection-edit']; //NEW explain

const routes: Routes = [
  {
    path:         '',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule),
    pathMatch:    'full',
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.loginAdmin],
    },
  },
  ...additional_routes,
  {
    path:         'calendar',
    loadChildren: () => import('./modules/calendar/calendar.page.module').then(m => m.CalendarPageModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.loginAdmin],
    },
  },

  //--- Overview-Pages & associated steppers
  {
    //Settings-documents-page
    path:         'directory-overview',
    loadChildren: () => import('./pages/documents/documents.module').then(m => m.DocumentsModule),
    canActivate:  [AuthGuard],
    data:         {
        reuseRoute:  true,
        permissions: [FrontendPermissions.documents_view],  // FIXME proper permission
    }
  },
  {
    path:         'new-directory',
    loadChildren: () => import('./steppers/documents/documents.module').then(m => m.DocumentsStepperModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.documents_edit],  // FIXME proper permission
    }
  },
  {
    // Time-correctoin-page
    path:         'time-corrections',
    loadChildren: () => import('./pages/time-corrections/time-corrections.module').then(m => m.TimeCorrectionsPageModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.loginAdmin, FrontendPermissions.timeCorrections_edit],
    },
  },
  //FIXME Rename remove profile
  {
    //Time correction stepper (in stats & checkin/time-correction)
    path:         'time-correction-profile',
    loadChildren: () => import('./steppers/time-correction/time-correction.module').then(m => m.TimeCorrectionModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.timeCorrections_edit],
    },
  },
  {
    //Time-tracking-set-overview
    path:         'time-tracking',
    loadChildren: () => import('./pages/time-tracking/time-tracking.module').then(m => m.TimeTrackingPageModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.loginAdmin, FrontendPermissions.timeTrackingSets_view],
    },
  },
  {
    //Time Tracking stepper
    path:         'time-tracking-profile',
    loadChildren: () => import('./steppers/time-tracking/time-tracking.module').then(m => m.TimeTrackingModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.timeTrackingSets_edit],
    },
  },
  {
    //Time Tracking Individual stepper
    path:         'time-tracking-profile-individual',
    loadChildren: () => import('./steppers/time-tracking-individual/time-tracking.module').then(m => m.TimeTrackingModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.timeTrackingSets_edit],
    },
  },
  //FIXME @jens, chris rename to permission-groups-overview
  {
    // Permission groups overview
    path:         'permission-groups',
    loadChildren: () => import('./pages/permission-groups/permission-groups-overview.module').then(m => m.PermissionGroupsOverviewPageModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.permissionGroups_view],
    },
  },
  {
    // Permission groups Stepper
    path:         'permission-groups',
    loadChildren: () => import('./steppers/permission-groups/permission-groups.module').then(m => m.GroupsModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.permissionGroups_edit],
    },
  },
  {
    //employee contract template overview (not gymcore)
    path:         'employee-contract-template-overview',
    loadChildren: () => import('./pages/contracts-employee/overview/employee-overview.module').then(m => m.EmployeeOverviewPageModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.loginAdmin, FrontendPermissions.employeeContractTemplates_view],
    },
  },
  {
    // EmployeeContract template stepper
    path:         'employee-contract-template',
    loadChildren: () => import('./steppers/employee-contract-template/employee-contract-template.module').then(m => m.EmployeeContractTemplateModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.employeeContractTemplates_edit],
    }
  },
  {
    // Product Management Pages
    path:         'product-management',
    loadChildren: () => import('./pages/product-management/product-management.module').then(m => m.ProductManagementModule),
    canActivate:  [AuthGuard],
    data:         {
        permissions: [FrontendPermissions.product_view]
    }
  },
  {
    // Product stepper
    path:         'product',
    loadChildren: () => import('./steppers/product-management/product/product.module').then(m => m.ProductModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.product_edit],
    },
  },
  {
    // Org Chart Overview
    path:         'org-chart',
    loadChildren: () => import('./pages/org-chart/org-chart.module').then(m => m.OrgChartModule),
    canActivate:  [AuthGuard],
    data:         {
        reuseRoute:  true,
        permissions: [FrontendPermissions.orgCharts_view],
    }
  },
  {
    // Org Chart stepper
    path:         'org-chart-edit',
    loadChildren: () => import('./steppers/org-chart/org-chart.module').then(m => m.OrgChartStepperModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.orgCharts_edit],
    }
  },
  //FIXME rename to absence-types-overview
  {
    //Absence types overview
    path:         'absence-types',
    loadChildren: () => import('./pages/absence/absence-types/absence-types.module').then(m => m.AbsenceTypesPageModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.absenceTypes_view],
    }
  },
  //FIXME rename to absence-types
  {
    //Absence types stepper
    path:         'new-absence-types',
    loadChildren: () => import('./steppers/absence-types/absence-types.module').then(m => m.AbsenceTypesModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.absenceTypes_edit],
    }
  },
  {
    //Projects overview page
    path:         'projects-overview',
    loadChildren: () => import('./pages/projects/overview/projects-overview.module').then(m => m.ProjectsOverviewPageModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.projects_view],
    }
  },
  {
    //Projet Stepper
    path:         'project',
    loadChildren: () => import('./steppers/projects/project.module').then(m => m.ProjectModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.projects_edit],
    }
  },
  {
    // Surcharge overview page
    path:         'surcharge-overview',
    loadChildren: () => import('./pages/surcharge/overview/surcharge-overview.module').then(m => m.SurchargeOverviewPageModule),
    canActivate:  [AuthGuard],
    data:         {
        permissions: [FrontendPermissions.surcharge_view],
    }
  },
  {
    // Surcharge stepper
    path:         'surcharge-stepper',
    loadChildren: () => import('./steppers/surcharge/surcharge.module').then(m => m.SurchargeModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.surcharge_edit],
    },
  },
  {
    // Terminals overview page
    path:         'terminals-overview',
    loadChildren: () => import('./pages/terminals/overview/terminals-overview.module').then(m => m.TerminalsOverviewPageModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.terminals_view],
    }
  },
  {
    //Terminals stepper
    path:         'terminal',
    loadChildren: () => import('./steppers/terminal/terminal.module').then(m => m.TerminalModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.terminals_edit],
    },
  },
  {
    //Transfer-Credit page
    path:         'absence-credit-transfer',
    loadChildren: () => import('./pages/absence/transfer-credit/absence-credit-transfer.module').then(m => m.AbsenceCreditTransferPageModule),
    canActivate:  [AuthGuard],
    data:         {
        permissions: [FrontendPermissions.absenceAccountTransfer_edit],
      },
  },
  {
    //Credit-Expiration page
    path:         'absence-credit-expiration',
    loadChildren: () => import('./pages/absence/credit-expiration/absence-credit-expiration.module').then(m => m.AbsenceCreditExpirationPageModule),
    canActivate:  [AuthGuard],
    data:         {
        permissions: [FrontendPermissions.absenceAccountExpiry_edit],
      },
  },
  {
    // DATEV
    path:         'datev',
    loadChildren: () => import('./pages/datev/datev-page.module').then(m => m.DatevPageModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.datev_edit,FrontendPermissions.datev_hr_eAU],
      feature:     FeatureFlags.datev
    }
  },
  {
    //DATEV connection edit
    path:         'datev-connection-edit',
    loadChildren: () => import('./steppers/datev-connection-edit/datev-connection-edit.module').then(m => m.DatevConnectionEditModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.datev_edit],
      feature:     FeatureFlags.datev
    },
  },
  {
    //DATEV eAU request
    path:         'eAU-request',
    loadChildren: () => import('./steppers/eAU-request/eAU-request.module').then(m => m.eAURequestModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.datev_hr_eAU],
      feature:     FeatureFlags.datev_hr_eau
    },
  },

  //--- Steppers menu
  {
    path:         'users',
    loadChildren: () => import('./steppers/users/users.module').then(m => m.UsersModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.employeeContracts_edit,FrontendPermissions.personBaseData_edit],
    },
  },
  {
    path:         'contract-employee',
    loadChildren: () => import('./steppers/contract-employee/contract-employee.module').then(m => m.ContractEmployeeModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.employeeContracts_edit],
    },
  },
  {
    path:         'company-settings',
    loadChildren: () => import('./steppers/company-settings/company-settings.module').then(m => m.CompanySettingsModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.companySettings_edit],
    },
  },
  {
    //Studio settings stepper (Company-settings)
    path:         'studio-settings',
    loadChildren: () => import('./steppers/studio-settings/studio-settings.module').then(m => m.StudioSettingsModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.companySettings_edit],
    },
  },


  //--- person-profile
  {
    path:         'person',
    loadChildren: () => import('./pages/person/person.module').then(m => m.PersonModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.loginAdmin],
    },
  },

  //--- steppers person-profile
  {
    //Time account edit
    path:         'user-time-account',
    loadChildren: () => import('./steppers/user-time-account/user-time-account.module').then(m => m.TimeAccountModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.employeeTimeAccounts_edit],
    },
  },
  {
    //credentials-stepper in person-profile
    path:         'credentials',
    loadChildren: () => import('./steppers/credentials/credentials.module').then(m => m.CredentialsModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.loginAdmin],
    },
  },
  {
    // absence credit account stepper - edit
    path:         'absence-credit-account',
    loadChildren: () => import('./steppers/absence-credit-account/absence-credit-account.module').then(m => m.AbsenceCreditAccountModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.absenceOverview_edit],
    },
  },
  {
    // user-document page
    path:         'user-document',
    loadChildren: () => import('./steppers/user-document/user-document.module').then(m => m.UserDocumentStepperModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.personDocuments_view],  // FIXME proper permission
    }
  },
  {
    // person-shift - edit
    path:         'person-shift',
    loadChildren: () => import('./steppers/person-shift/person-shift.module').then(m => m.PersonShiftModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.personShiftplan_edit],
    }
  },


  //--- Pages menu
  {
    path:         'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
  },
  {
    // has permission in child-routes
    path:         'analyses',
    loadChildren: () => import('./pages/analyses/analyses-page.module').then(m => m.AnalysesPageModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.loginAdmin],
    },
  },
  {
    //Absence Statistics selection
    path:         'absence-selection',
    loadChildren: () => import('./pages/analyses/absences/absence-selection.module').then(m => m.AbsenceSelectionPageModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.absenceStats_view],
    }
  },
  {
    //Salary Statistics selection
    path:         'salaries',
    loadChildren: () => import('./pages/analyses/salaries/salaries.module').then(m => m.SalariesPageModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.salaryStats_View],
    }
  },
  {
    //Attence overview page
    path:         'attendance-overview',
    loadChildren: () => import('./pages/attendance/attendance-overview.module').then(m => m.AttendancePageModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.attendanceList_view],
    }
  },
  {
    //vat rate overview page
    path:         'vat-rate-overview',
    loadChildren: () => import('./pages/cashflow/vat-rate/overview/vat-rate-overview.module').then(m => m.VatRateOverviewPageModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.cashflowSettings_view],
    }
  },
  {
    //vat rate stepper
    path:         'vat-rate',
    loadChildren: () => import('./steppers/cashflow/vat-rate/vat-rate.module').then(m => m.VatRateModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.cashflowSettings_edit],
    },
  },
  {
    //accounting account overview page
    path:         'accounting-account-overview',
    loadChildren: () => import('./pages/cashflow/accounting-account/overview/accounting-account-overview.module').then(m => m.AccountingAccountPageModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.cashflowSettings_view],
    }
  },
  {
    //accounting account stepper
    path:         'accounting-account',
    loadChildren: () => import('./steppers/cashflow/accounting-account/accounting-account.module').then(m => m.AccountingAccountModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.cashflowSettings_edit],
    },
  },
  {
    //bank account overview page
    path:         'bank-account-overview',
    loadChildren: () => import('./pages/cashflow/bank-account/bank-account.module').then(m => m.BankAccountPageModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.cashflowSettings_view],
    }
  },
  {
    //bank account stepper
    path:         'bank-account',
    loadChildren: () => import('./steppers/cashflow/bank-account/bank-account.module').then(m => m.BankAccountModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.cashflowSettings_edit],
    },
  },
  {
    //direct debit group overview page
    path:         'direct-debit-group-overview',
    loadChildren: () => import('./pages/cashflow/direct-debit-group/direct-debit-group.module').then(m => m.DirectDebitGroupPageModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.cashflowSettings_view],
    }
  },
   {
    //direct debit group stepper
    path:         'direct-debit-group',
    loadChildren: () => import('./steppers/cashflow/direct-debit-group/direct-debit-group.module').then(m => m.DirectDebitGroupModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.cashflowSettings_view],
    }
  },
  {
    path:          'privacy-policy',
    loadComponent: () => import('./standalone/privacy-policy/privacy-policy.page').then(p => p.PrivacyPolicyPage),
  },
  {
    path:          'something-went-wrong',
    loadComponent: () => import('./standalone/error/error.page').then(p => p.ErrorPage),
    canActivate:   [AuthGuard],
    data:          {
      permissions: [FrontendPermissions.loginAdmin],
    },
  },
  {
    path:          'version-to-old',
    loadComponent: () => import('./standalone/version-to-old/version-to-old.page').then(p => p.VersionToOldPage),
  },
  {
    path:          'maintenance',
    loadComponent: () => import('./standalone/maintenance/maintenance.page').then(p => p.MaintenancePage),
  },
  {
    path:          'exit',
    loadComponent: () => import('./draft-system/components/draft-exit.page').then(c => c.DraftExitPage),
    canActivate:   [AuthGuard],
    data:          {
      permissions: [FrontendPermissions.loginAdmin],
    },
  },
  {
    path:          'exit-animated',
    loadComponent: () => import('./draft-system/components/draft-exit.page').then(c => c.DraftExitPage),
    canActivate:   [AuthGuard],
    data:          {
      isCalm:      false,
      permissions: [FrontendPermissions.loginAdmin]
    },
  },
  //FIXME can be removed whenever there is time to check if its still in use
  {
    path:          'empty',
    loadComponent: () => import('./standalone/empty/empty.page').then(m => m.EmptyPage)
  },
  {
    path:          'logout',
    loadComponent: () => import('./standalone/logout/logout.page').then(p => p.LogoutPage),
  },


  //--- Steppers somewhere
  {
    //FIXME @jens, @chris, @rene - path
    //Modal step in e.g. absence overview
    path:         'absence/modal',
    loadChildren: () => import('./steppers/absence/absence.module').then(m => m.AbsenceStepperModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.absenceOverview_edit],
    }
  },

  //FIXME remove ?? check if its linked properly
  // {
  //   path:          'not-found-404',
  //   loadComponent: () => import('./standalone/404/not-found.page').then(p => p.NotFoundPage),
  //   canActivate:   [AuthGuard],
  //   data:          {
  //     permissions: [Permission.App_Login_Frontend],
  //   },
  // },

  {
    // MCP
    path:          'session/:studio/:sessionID',
    loadComponent: () => import('./standalone/empty/empty.page').then(m => m.EmptyPage),
    resolve:       {
      A: (route: ActivatedRouteSnapshot) => inject(InternalAppService).__activateSession(route.params.studio, route.params.sessionID)
    }
  },
  {
    // MCP data import
    path:         'import',
    loadChildren: () => import('./pages/import/import.module').then(m => m.ImportModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.ClusterAdmin],
    },
  },
  { // this have to be the last route entry!
    path:          '**',
    // FIXME @Chris this component changes the default button/dropdown style (blue)
    // and this should be an direct app.component and not lazy loaded anyway
    loadComponent: () => import('./standalone/404/not-found.page').then(p => p.NotFoundPage),
  },
];

const combinedRoutes = routes.map(x => {
  if (modalRoutes.includes(x.path))
    return [x, {outlet: 'modal', ...x}];
  else return x;
}).flat(1);

@NgModule({
  imports: [
    RouterModule.forRoot(combinedRoutes, {
      preloadingStrategy:        PreloadAllModules,
      enableTracing:             false,
      anchorScrolling:           'enabled',
      scrollPositionRestoration: "enabled",
      bindToComponentInputs:     true,
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
