import { IUser, WithFiles } from "@vierkant-software/types__api";
import { Dict } from "../types/global";

export type Formats = "initial" | "initialNoDot" | "normal";
export type FormatObj = {firstname: Formats | "none", lastname: Formats | "none"};
export type Format = (Formats) | FormatObj;


export function formatUser(value: IUser, format: Format = "normal"){
    if (!value)
        return undefined;
    if (typeof format === "object"){
        return `${getValue(value.firstname, format.firstname)} ${getValue(value.lastname, format.lastname)}`;
    } else {
        let name = `${getValue(value.firstname, format)} ${getValue(value.lastname, format)}`;
        if (format === "initialNoDot")
            name = name.replace(" ", "");
        return name;
    }
}

function getValue(input: string, format: Formats | "none"){
    if (!input || input.trim() === "")
        return '';
    switch(format) {
        case "none":
            return '';
        case "initial":
            return input[0].toUpperCase() + '.';
        case "initialNoDot":
            return input[0].toUpperCase();
        default:
            return input;
    }
}

/**
 * Parse the backend {@link WithFiles<IUser[]>} structure when fetching users
 * into two dicts of users and files by the user ID.
 * 
 * Makes handling users and their images easier in pages.
 * 
 * @param data Default result when fetching users from the backend
 * @returns Dicts of users and files by their user ID.
 */
export function parseUserWithFiles(data: WithFiles<IUser[]>) {
    const files: Dict<string> = {};
    const users: Dict<IUser> = {};

    data.data.forEach((val, index) => {
        users[val.ID] = val;
        files[val.ID] = data.__files[index];
    });

    return { users, files };
}