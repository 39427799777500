import { EventInput } from "@fullcalendar/core";
import {
    WithFiles,
    IUser,
    IOpeningTimeException,
    CalendarWeek,
    WeekdayFC,
    IShiftPlanTemplate
} from "@vierkant-software/types__api";
import { DateTime, Duration } from "luxon";
import { toDateTime } from "src/util/toDateTime";
import { Prompt } from "src/util/types/global";
import { ShiftEditStep } from "./shift-edit.step";

export type ResolveData = {
    templates: IShiftPlanTemplate[];
    openingTimes: EventInput[];
    employees: WithFiles<IUser[]>;
    holidays: IOpeningTimeException[];
};

export class CW implements CalendarWeek{
    public week: number;
    public year: number;

    private constructor(week: number, year: number) {
        this.week = week;
        this.year = year;
    }

    public static fromCW(cw: CalendarWeek){
        return new CW(cw.week, cw.year);
    }

    public static fromDT(date: Date | DateTime){
        date = toDateTime(date);
        return new CW(date.weekNumber, date.weekYear);
    }

    public compare(other: {week: number, year: number}){
        return other && this.week === other.week && this.year === other.year;
    }

    public toDateTime(day: WeekdayFC = WeekdayFC.Monday, time: Duration = undefined){
        const dateTime = DateTime.fromObject({weekYear: this.year, weekNumber: this.week, weekday: day});
        return time ? dateTime.plus(time) : dateTime;
    }

    public toID(prefix = ""){
        const id = `${this.year}-${this.week}`;
        return prefix ? `${prefix}_${id}` : id;
    }

    public valueOf(){
        return {week: this.week, year: this.year};
    }
}

/**
 * Change type definition from DateTime to date.
 * Fullcalendar with Luxon integration accepts DateTimes, 
 * but the API only allows objects of date type.
 * We can spoof the object to make Fullcalendar accept the Luxon object
 *
 * @param date DateTime object to spoof
 * @returns Returns spoofed DateTime
 */
export function dt(date: DateTime){
    return date as unknown as Date;
}

export interface ResetPrompt<TData = unknown> extends Prompt<ShiftEditStep, TData> {
    toDelete?: "all" | "assignments";
}

export interface WeekCopyPrompt<TData = DateTime> extends Prompt<ShiftEditStep, TData> {
    onExisting?: "merge" | "overwrite";
    hasExisting?: boolean;
}
/**
 * Duration on top of the current week (exclusive)
 * Allows editing of up to 6 weeks
 */
export const MAX_DURATION = Duration.fromObject({ weeks: 5 });
