import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, UrlSerializer } from '@angular/router';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule, TranslateLoader, TranslateParser } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { primeNgModule } from './prime-ng-modules';
import { SharedModule } from './shared.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './components/components.module';
import { AuthGuard } from 'src/services/auth.guard';
import { SidebarComponent } from './sidebar/siderbar.component';
import { AppService, InternalAppService } from 'src/services/app.service';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { LeftSidebarComponent } from './components/left-sidebar/left-sidebar.component';
import { ByteSizePipe } from './pipes/byteSize.pipe';
import { ConfirmationService, MessageService } from 'primeng/api';
import { initServicesFactory } from 'src/services/init-services.factory';
import { CommonModule } from '@angular/common';
import { GCRouteReuseStrategy } from './route-reuse-strategy';
import { Globals } from './services/globals.service';
import { DebugFlags, DraftModule, DraftUrlSerializer } from 'src/app/modules/ngx-draft';
import { environment } from 'src/environments/environment';
// import { GcFullCalendarModule } from './modules/calendar/components/gc-fullcalendar/fullcalendar.component.module';
import { GenericErrorHandler } from 'src/services/generic.errorhandler';
import { AttendanceService } from './pages/attendance/attendance.service';
import { DebugService } from 'src/services/debug.service';
import { GcLayoutModule } from './components/layout/layout.module';
import { GcToastModule } from './modules/ngx-components/lib/wrappers/components/toast';
import { GcTranslateParser } from 'src/services/GcTranslateParser.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json?cb=' + Date.now());
}

@NgModule({

  declarations: [
    AppComponent,
    SidebarComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GcLayoutModule,
    primeNgModule,
    SharedModule,
    ComponentsModule,
    GcToastModule,
    ConfirmDialogModule,
    TranslateModule.forRoot({
      defaultLanguage: 'de-DE',
      loader:          {
        provide:    TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps:       [HttpClient]
      },
      parser: { provide: TranslateParser, useClass: GcTranslateParser },
    }),
    DraftModule.forRoot(InternalAppService, { debug: environment.production ? undefined : DebugFlags.enable }),
  ],
  providers: [
    { provide: DebugService, useFactory: () => DebugService.instance },
    { provide: UrlSerializer, useClass: DraftUrlSerializer },
    {
      provide:  APP_INITIALIZER,
      useClass: initServicesFactory,
      multi:    true,
    },
    {
      provide:  RouteReuseStrategy,
      useClass: GCRouteReuseStrategy,
    },
    { provide: Globals, useValue: Globals },
    AuthGuard,
    MessageService,
    ConfirmationService,
    InternalAppService,
    AttendanceService,
    ByteSizePipe,
    { provide: AppService, useExisting: InternalAppService },
    { provide: TopBarComponent, useFactory: () => TopBarComponent.instance },
    { provide: LeftSidebarComponent, useFactory: () => LeftSidebarComponent.instance },
    { provide: SidebarComponent, useFactory: () => SidebarComponent.instance },
    { provide: Location, useValue: window.location },
    ...(environment.production ? [] : [{ provide: ErrorHandler, useClass: GenericErrorHandler }]),
    provideHttpClient(withInterceptorsFromDi()),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
