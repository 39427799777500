import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faAt, faBirthdayCake, faGear, faMobileRetro } from '@fortawesome/free-solid-svg-icons';
import { PersonShortcutSidebarComponent } from 'src/app/sidebar/components/person-shortcut/person-shortcut.sidebar.component';
import { SidebarComponent } from 'src/app/sidebar/siderbar.component';
import { ContactType, FrontendPermissions, IContact, IEmployeeContractAdditionalInfo, IRemark, IUserEx } from '@vierkant-software/types__api';
import { DateTime } from 'luxon';
import { Globals } from 'src/app/services/globals.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { DraftService } from 'src/app/modules/ngx-draft';
import { PageOptions } from 'src/services/pageOptions.decorator';
import { sortBy } from 'src/util/sort';
import { AppService } from 'src/services/app.service';

const ContactTypeDefs = {  //TODO global
	[ContactType.Undefined]: { label: 'Kontakt',icon: '',inputType: 'text' },
	[ContactType.EMail]:     { label: 'E-Mail', icon: 'iconAt', inputType: 'email' },
	[ContactType.Phone]:     { label: 'Telefon', icon: 'faPhone', inputType: 'tel' },
	[ContactType.Mobile]:    { label: 'Mobil', icon: 'faMobileRetro', inputType: 'tel' },
	[ContactType.Fax]:       { label: 'Fax', icon: 'faFax', inputType: 'tel' },
};

@Component({
  templateUrl: './overview.page.haml',
  styleUrls:   ['./overview.page.sass'],
})
@PageOptions<PersonOverviewPage>({
  showInnerWrapper: false
})
export class PersonOverviewPage {

  iconCake = faBirthdayCake;
  iconGear = faGear;
  iconPhone = faMobileRetro;
  iconAt = faAt;

  @Input() selectedShortcuts: string[] = [];
  person: IUserEx & {image: string};
  @ViewChild('opHasContractsWithoutEnd', {static: true}) opHasContractsWithoutEnd: OverlayPanel;

  readonly title: string = "Einstellungen";

  PersonShortcutSidebarComponent = PersonShortcutSidebarComponent;
  gender = ['Unbekannt', 'Männlich', 'Weiblich', 'Divers'];

  contacts: IContact[][] = [];
  contracts: IEmployeeContractAdditionalInfo[];

  get id(){
    return this.person?.ID;
  }

  get hasContractsWithoutEnd(): boolean{
    return !!this.contracts.filter(c => !c.end)?.length;
  }

  pEdit = FrontendPermissions.employeeContracts_edit;
  pView = FrontendPermissions.personBaseData_view;
  cpView = FrontendPermissions.employeeContracts_view;
  pContract = [FrontendPermissions.employeeContracts_edit, FrontendPermissions.employeeContracts_view, FrontendPermissions.employeePermissions_view];

  constructor(
    private activeRoute: ActivatedRoute,
    private rightSideBar: SidebarComponent,
    public global: Globals,
    private router: Router,
    private draftService: DraftService,
    private appService: AppService
  ) {
    this.activeRoute.data.subscribe(({ person, contracts }) => {
      this.person = person.data;
      if (person.__files?.length)
        this.person.image = person.__files[0];
      const now = DateTime.now().startOf('day').valueOf();
      if (contracts !== false) {
        this.contracts =  sortBy(
          (contracts as IEmployeeContractAdditionalInfo[]).filter(x => !x.end || x.end.valueOf() - now >= 0),
          x => (now - x.end.valueOf()) < 0 ? - 1 : 1,
          x => x.begin.valueOf()
        ).slice(0, 2);
      }
      for (let i = 0; i < this.person.contacts?.length; i += 3)
        this.contacts.push(this.person.contacts.slice(i, i + 3));
      if (this.person.birthday)
        this.calculateAge(this.person.birthday);
    });
  }

  isViewEmployeeContractsActive(){
    if (this.appService.getFrontendPermission(this.cpView))
        return true;
    else
        return false;
  }

  isViewPersonBaseActive(){
    if (this.appService.getFrontendPermission(this.pView))
        return true;
    else
        return false;
  }

  async goToEmployeeContracts(id: string){
    if (this.appService.getFrontendPermission(this.cpView))
        await this.router.navigate(['/', 'person', id, 'employee-contracts-overview']);
    else
        return;
  }

  async goToPersonData(id: string){
    if (this.appService.getFrontendPermission(this.pView))
        await this.router.navigate(['/', 'person', id, 'data']);
    else
        return;
  }

    getLabel(contact: IContact): string { //TODO global
		return (
			ContactTypeDefs[contact?.type]?.label ??
			ContactTypeDefs[ContactType.Undefined].label
		);
	}

  getState(contract: IEmployeeContractAdditionalInfo): boolean{
      if (!contract || !contract.begin) return false;
      const today = DateTime.now().startOf('day');
      if (contract.begin.startOf('day') > today) return false;
      if (!!contract.end && contract.end.startOf('day') < today) return false;
      return true;
  }

  getValue(contact: IContact) {
    return contact?.value;
  }

  calculateAge(birthday: DateTime){
    const years = (DateTime.isDateTime(birthday) ? birthday : DateTime.fromJSDate(birthday)).diffNow("years").years * -1;
    return Math.floor(years);
  }

  byImportance(a: IRemark, b: IRemark){
    if (a.importance > b.importance)
      return 1;
    if (a.importance < b.importance)
      return -1;
    return 0;
  }

  byPrimary(a: IContact, b: IContact) {
    if (a.isPrimary)
      return -1;
    if (b.isPrimary)
      return 1;
    return 0;
  }

  openSideBar(){
    this.rightSideBar.clear();
    this.rightSideBar.addItem(PersonShortcutSidebarComponent, { selected: this.selectedShortcuts });
    this.rightSideBar.visible = true;
  }

  createContract(ev: Event){
    ev.stopPropagation();
    if (this.hasContractsWithoutEnd)
      { this.opHasContractsWithoutEnd.toggle(ev); }
    else {
      if (!this.id?.length)  return;
        this.draftService.createDraft('contract-employee', 'contract-selection',{
          openAsModal: false,
          data:        {
            userToLoadID: this.id,
          },
        }).catch(console.error);
    }
  }
}
