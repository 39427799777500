import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Inheritance, InheritanceType } from "../workflow/declarations";
import { OrgChartEntry } from "../gc-org-chart/gc-org-chart.component";
import { VisibilityName } from "@vierkant-software/types__api";

type DepartmentEntry = OrgChartEntry & { children?: DepartmentEntry[] };
export type FormType = {
};

@Component({
    selector:    "v-visibility-inheritance",
    styleUrls:   ["./visibility-inheritance.component.sass"],
    templateUrl: "./visibility-inheritance.component.haml",
})

export class VisibilityInheritanceComponent implements OnChanges {

    @Input() public rootID: string;

    @Input() public departmentNodes: DepartmentEntry[];

    @Input() public visibility: VisibilityName;
    @Input() public inhertiance: Inheritance;
    @Output() public close: EventEmitter<Record<string, InheritanceType>> = new EventEmitter<Record<string, InheritanceType>>();

    public departments: Record<string, DepartmentEntry> = {};
    public form = new FormGroup({});
    public controls: Record<string, FormControl> = {};
    public rootNode: DepartmentEntry;

    public setup() {
        if (this.visibility === undefined || !this.departmentNodes || !this.rootID)
            return;
        this.reset();
        this.departmentNodes.forEach(dep => {
            const depCopy = {...dep};
            const parent = this.departments[dep.parentID];
            if (parent) (parent.children ??= []).push(depCopy);
            this.departments[dep.ID] = depCopy;
            const defaultValue = parent ? "inherit" : "custom";
            const origValue = (!this.visibility || dep.visibility.useDefault ?
                dep.visibility.defaultEntry :
                dep.visibility.entries[this.visibility]
            )?.inheritance ?? defaultValue;

            if (this.form.get(dep.ID))
                this.form.get(dep.ID).patchValue(origValue);
             else
                this.form.addControl(dep.ID, this.controls[dep.ID] = new FormControl(origValue));
        });
        this.rootNode = this.departments[this.rootID];
    }

    public reset() {
        this.form = new FormGroup({});
        this.rootNode = null;
        this.departments = {};
        this.controls = {};
    }

    public cancel() {
        this.close.emit(undefined);
        this.onClose();
    }

    public confirm() {
        this.close.emit(this.form.value);
        this.onClose();
    }

    private onClose() {
        this.form.reset();
    }

    ngOnChanges(_changes: SimpleChanges): void {
        this.setup();
    }
}
